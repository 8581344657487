<template>
  <div>
    <el-divider content-position="left" class="tittle">Reprint labels list</el-divider>
    <el-form :inline="true" :model="searchForm" class="demo-form-inline" ref="ruleForm">
      <el-form-item prop="tracking_id">
        <el-input v-model="searchForm.tracking_id" @keyup.native.enter="onSubmit" placeholder="Tracking Id"></el-input>
      </el-form-item>
      <el-form-item prop="status">
        <el-select v-model="searchForm.status" placeholder="Please select status">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="date">
        <el-date-picker v-model="searchForm.date" type="daterange" range-separator="To"
          start-placeholder="Start to create" end-placeholder="End to create" value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>
      <el-form-item class="searchBtn">
        <el-button type="primary" @click="onSubmit">Search</el-button>
        <el-button type="default" @click="resetForm('ruleForm')">Reset</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="created_at" label="Created at" min-width="160">
      </el-table-column>
      <el-table-column prop="old_shipment.tracking_id" label="Old tracking" align="center" min-width="120">
      </el-table-column>
      <el-table-column prop="new_shipment.tracking_id" label="New tracking" align="center" min-width="120">
      </el-table-column>
      <el-table-column prop="status_desc" label="Status" align="center" min-width="130">
      </el-table-column>
      <el-table-column prop="printed_at" label="Print at" min-width="160" align="center">
      </el-table-column>
      <el-table-column prop="label" label="Label" min-width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.label" class="iconfont icon-iconset0266" style="
                cursor: pointer;
                color: #606266;
                text-decoration: none;
                padding-right: 8px;
              " @click="printed(scope.row)"></span>
          <span v-else>Creating</span>
        </template>
      </el-table-column>
      <el-table-column prop="reason" label="Reason" min-width="220" :show-overflow-tooltip="true">
      </el-table-column>
    </el-table>
    <!-- 上一页和下一页 -->
    <div class="btn">
      <el-button type="primary" @click="reduce" :disabled="currentid == 0">previous page
      </el-button>
      <el-button type="primary" @click="add" :disabled="length < 20">next page</el-button>
    </div>
    <!-- 创建JOB的弹窗 -->
    <!-- <el-dialog title="Create Blocks" :visible.sync="createdialog" width="50%">
      <div class="muli-tags" @click="createfocus">
        <div>
          <el-button
            v-for="(tag, index) in createForm.tracking_ids"
            :key="index"
            size="mini"
            class="id-btn"
            plain
          >
            {{ tag
            }}<span class="del" @click="createTag(index)">X</span></el-button
          >
        </div>
      </div>
      <div class="input-cont">
        <div class="trackid">Tracking Id</div>
        <input
          type="text"
          ref="input"
          v-model="createcurrent"
          @keyup.enter="create"
          placeholder="Please enter tracking id and press ENTER to confirm each one."
        />
      </div>
      <el-form :inline="true" class="demo-ruleForm" label-width="auto">
        <el-form-item label="Cause description" class="desc-box">
          <el-input
            type="textarea"
            v-model="createForm.reason"
            class="desc"
            autosize
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createCancel" size="mini">Cancel</el-button>
        <el-button
          type="primary"
          size="mini"
          @click="surecreate"
          :disabled="createForm.tracking_ids.length === 0"
          >Submit</el-button
        >
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import { reprintLabels, reprint } from '@/api/user.js'
export default {
  data () {
    return {
      length: '',
      ltid: 0,
      gtid: '',
      currentid: 0,
      isfirst: true,
      currentdata: [],
      searchForm: {
        date: '',
        name: '',
        status: '',
        number: '',
        tracking_id: ''
      },
      createForm: {
        tracking_ids: [],
        reason: ''
      },
      options: [
        { value: 1, label: 'Awaiting to print' },
        { value: 2, label: 'Printed' }
      ],
      tableData: []
      // createdialog: false,
      // createcurrent: ''
    }
  },
  created () {
    this.reprintlist()
  },
  methods: {
    // 渲染列表
    async reprintlist () {
      const data = await reprintLabels({
        ltid: this.ltid,
        gtid: this.gtid,
        start_date: this.searchForm.date[0],
        end_date: this.searchForm.date[1],
        tracking_id: this.searchForm.tracking_id,
        status: this.searchForm.status
      })
      // console.log(data)
      this.tableData = data
      this.length = data.length
      if (data.length > 0) {
        this.ltid = data[data.length - 1].id
        if (this.isfirst) {
          this.maxid = data[0].id
          this.isfirst = false
        }
        // 上一页禁用
        if (data[0].id === this.maxid) {
          this.currentid = 0
        }
      }
    },
    onSubmit () {
      this.ltid = 0
      this.gtid = ''
      this.isfirst = true
      this.reprintlist()
    },
    resetForm () {
      this.searchForm.data = ''
      this.$refs.ruleForm.resetFields()
      this.ltid = 0
      this.gtid = ''
      this.currentid = 0
      this.reprintlist()
    },
    // 上一页
    reduce () {
      if (this.tableData.length > 0) {
        this.ltid = ''
        this.gtid = this.tableData[0].id
      } else {
        this.gtid = ''
        this.ltid = this.currentdata[0].id + 1
      }
      this.reprintlist()
    },
    // 下一页
    add () {
      this.currentdata = this.tableData
      this.gtid = ''
      this.currentid = this.ltid
      this.reprintlist()
    },
    async printed (obj) {
      if (obj.status === 1) {
        const msg = await reprint({
          id: obj.id
        })
        if (msg.status === 200) {
          // this.$message({
          //   message: 'Operation successfully',
          //   type: 'success'
          // })
          this.ltid = this.tableData[0].id + 1
          this.gtid = ''
          const tempwindow = window.open('_blank')
          tempwindow.location.href = obj.label
          this.reprintlist()
        } else {
          this.$message({
            message: msg.data.toperr,
            type: 'error'
          })
        }
      } else {
        const tempwindow = window.open('_blank')
        tempwindow.location.href = obj.label
      }
    }
    // create(e) {
    //   const val = e.target.value
    //   if (!val) return
    //   // 如果已经存在相同tag，不再添加
    //   if (this.createForm.tracking_ids.indexOf(val) > -1) {
    //     this.$message({
    //       message: 'The tracking id already exists.',
    //       type: 'error'
    //     })
    //     return
    //   }
    //   // 把输入值添加到tag，并清空文本框
    //   this.createForm.tracking_ids.push(val)
    //   this.createcurrent = ''
    // },

    // createJob() {
    //   this.createdialog = true
    // },
    // createfocus() {
    //   this.$refs.input.focus()
    // },
    // createTag(index) {
    //   this.createForm.tracking_ids.splice(index, 1)
    // },
    // surecreate() {},
    // createCancel() {
    //   this.createdialog = false
    //   this.createcurrent = ''
    //   this.createForm.tracking_ids = []
    // }
  }
}
</script>

<style lang="less" scoped>
.icon-iconset0266 {
  font-size: 18px;
}

.el-divider__text.is-left {
  font-size: 30px;
  font-weight: bold;
  color: #333;
}

.tittle {
  margin-bottom: 60px;
}

/deep/.el-table tr th {
  background-color: #f5f7fa;
}

.btn {
  margin-top: 40px;
  text-align: right;
}

.searchBtn {
  margin-left: 20px;
}

.id-btn {
  margin: 0 10px 10px 0;
}

// .add {
//   background-color: #009688;
//   color: #fff;
// }
// .del {
//   margin-left: 3px;
//   display: inline-block;
//   padding: 2px 3px;
//   color: #c0c4cc;
//   &:hover {
//     background: #f56c6c;
//     color: #fff;
//   }
// }
// .del-content {
//   display: flex;
//   align-items: center;
//   margin: 40px 0;
// }
// .trackid {
//   text-align: right;
//   font-size: 14px;
//   height: 40px;
//   line-height: 40px;
//   padding-right: 12px;
//   width: 120px;
//   color: #606266;
// }
// .input-cont {
//   display: flex;
//   margin-bottom: 40px;
//   box-sizing: border-box;
//   input {
//     border: 1px solid #dcdfe6;
//     border-radius: 5px;
//     padding: 0 15px;
//     height: 40px;
//     outline: none;
//     flex: 1;
//     &::placeholder {
//       color: #c0c4cc;
//     }
//   }
// }
// .muli-tags {
//   margin-left: 120px;
//   padding: 11px;
// }
// .desc-box {
//   width: 100%;
//   display: flex;
//   /deep/.el-form-item__content {
//     flex: 1;
//   }
// }
// /deep/.el-textarea__inner {
//   height: 100px !important;
// }
</style>
